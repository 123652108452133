import React, { useState } from 'react';
import format from 'xml-formatter';
import uuidv4 from 'uuid/v4';

//CSS
import modalStyles from './DocumentMarcDisplayModal.module.scss';
import styles from '../helpDialog.module.scss';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Close } from '@material-ui/icons'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import '../../styles/basic_style.scss';
import { height } from '@material-ui/system';


const SimpleDialogDemo = (props) => {

  const options = { collapseContent: true, lineSeparator: "\n" };
  const formattedXml = format(props.data2, options);

  const [open, setOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('marc');

  function handleClickOpen() {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const menuItems = {
    marc: {
      name: 'MARC',
      onClick: () => setSelectedMenu('marc')
    },
    marcXml: {
      name: 'MARCXML',
      onClick: () => setSelectedMenu('marcXml')
    }
  }

  return (
    <div className="asdasd">
      <div
        onClick={handleClickOpen}
        className={styles.BookInfoDialogButton}
      >
        <span className={styles.bookInfoDialogButtonIcon}><AssignmentOutlinedIcon /></span>
        <span className={styles.bookInfoDialogButtonText}>Megjelenítés</span>
      </div>
      
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
        className={styles.helpDialogBox}
        // style={{height: '90vh'}}
        PaperProps={
          styles.helpPaperBlock
        }
      >
        <div className={styles.helpPaperBlock}>


        <div className={modalStyles.headerContainer}>
          {Object.keys(menuItems).map(menuItem => (
            <DialogTitle
              className={selectedMenu === menuItem ? [modalStyles.menuItem, modalStyles.activeMenuItem].join(' ') : modalStyles.menuItem}
              onClick={menuItems[menuItem].onClick}
              key={menuItem}>
              {menuItems[menuItem].name}
            </DialogTitle>
          ))}
        </div>


        <div className={styles.helpDialogContent}>
          <Button
            className={styles.closeButton}
            onClick={handleClose}
          >
            <Close />
          </Button>
          {selectedMenu === 'marc' ? props.data1.split('\n').map(row => <div key={row}>{row}</div>) : formattedXml.split('\n').map(row => <div key={uuidv4()}>{row}</div>)}
        </div>


      </div>
      </Dialog>
    </div>
  );
}

export default SimpleDialogDemo;
