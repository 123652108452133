import React from 'react';
import uuidv4 from 'uuid/v4';

//CSS
import {
    Grid,
    Box
} from '@material-ui/core';

import MailoIcon from "@material-ui/icons/MailOutline"
import PrinterIcon from "@material-ui/icons/Print"
import LinkIcon from "@material-ui/icons/Link"
import elementTemplateStyles from './DocumentFuncButtons.module.scss'
import '../../styles/basic_style.scss';

//COMPONENTS
import DocRefDialog from '../DocumentMarcDisplayModal/DocumentReference'


const DocumentFuncButtons = props => {

    let finalUrl = '';

    if(props.url[0]==='/'){
        finalUrl = (location.origin+props.url).toString();
    }
    else{
        finalUrl = props.url.toString();
    }


    const tooltipId = uuidv4();

    const functionalButtons = [
        {
            name: 'E-mail',
            icon: <MailoIcon />,
            attrs: {
                onClick: () => mailFunction()
            }
        },
        {
            name: 'Nyomtatás',
            icon: <PrinterIcon />,
            attrs: {
                onClick: () => printHandler()
            }
        },
    ]

    const printHandler = () => {
        if (props.url === window.location.href)
            window.print()
        else {
            window.open(props.url+'?toPrint=true','_blank','noopener')
        }
    }

    const mailFunction = () => {
        let url = 'mailto:';
        url += '?subject=';
        url += '&body=' + finalUrl;
        window.open(url);
    }

    const linkFunction = () => {
        const copyText = document.createElement('input');
        copyText.value = finalUrl;
        copyText.setAttribute('readonly', '');
        document.body.appendChild(copyText)
        copyText.select();
        document.execCommand("copy");
        document.body.removeChild(copyText);
        const tooltip = document.getElementById(tooltipId);
        tooltip.innerHTML = "Link sikeresen vágólapra másolva";
    }

    const linkFunctionMouseOutHandler = () => {
        const tooltip = document.getElementById(tooltipId);
        tooltip.innerHTML = "Link másolása";
    }

    return (
        <div className={'dataBox'}>
            <Box className={'docDataIconLinks'}>
                <Grid
                    container
                    direction="row"
                >
                    <Grid item className={'docDataLinkItem'}>
                        <DocRefDialog data1={props.data1} data2={props.data2} />
                    </Grid>

                    {functionalButtons.map(functionalButton => (
                        <Grid item className={'docDataLinkItem'} key={functionalButton.name}>
                            <a {...functionalButton.attrs} className={elementTemplateStyles.functionButtons}>
                                <span className={'docDataLinkIcon'}>{functionalButton.icon}</span>
                                <span>{functionalButton.name}</span>
                            </a>
                        </Grid>
                    ))}
                    <Grid item className={'docDataLinkItem'}>
                        <div className={elementTemplateStyles.tooltip}>
                            <a onClick={linkFunction} onMouseOut={linkFunctionMouseOutHandler} className={elementTemplateStyles.functionButtons}>
                                <span className={'docDataLinkIcon'}><LinkIcon /></span>
                                <span className={elementTemplateStyles.tooltiptext} id={tooltipId}>Link másolása</span>
                                <span>Link</span>
                            </a>
                        </div>
                    </Grid>

                </Grid>
            </Box>
        </div>
    )
}

export default DocumentFuncButtons;
