import React, { useState, useEffect } from 'react';
//GASTBY
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'gatsby';
//CSS
import { Snackbar } from '@material-ui/core';
//COMPONENTS
import ErrorSnackbar from '../ErrorSnackbar/ErrorSnackbar';

const APOLLO_QUERY_RELATED_DOCS = gql`
    query($query: JSON!, $size: Int!){
    elasticDocuments(
      query: $query
      from: 0
      size: $size
      sort: []
      aggs: {}
    )
    {documentsPayload}
  }
`

const RelatedRecords = ({ relatedRecords }) => {

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: ''
  })

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false, message: '' })
  }

  const relatedRecordsObject = [];
  for (let i = 0; i < relatedRecords.length; i++) {
    if (relatedRecords[i].value.length > 0) {
      for (let k = 0; k < relatedRecords[i].value.length; k++) {
        relatedRecordsObject.push({ ...relatedRecords[i].value[k], name: relatedRecords[i].name })
      }
    }
  }

  const originalIds = relatedRecordsObject.map(record => (
    { match: { originalId: record.parameter } }
  ))

  const { data, loading, error } = useQuery(APOLLO_QUERY_RELATED_DOCS, {
    variables: {
      query: {
        bool: {
          must: [],
          must_not: [],
          should: originalIds
        }
      },
      size: originalIds.length
    }
  });

  const displayRelatedDocs = () => {
    let relatedRecordsDisplay = [];
    if (data && !loading) {
      const relatedHits = data.elasticDocuments.documentsPayload.hits.hits;

      relatedRecordsDisplay = relatedRecordsObject.map(relatedRecord => {
        let documentId = null;
        let documentTitle = null;
        
        if (relatedHits.length > 0) {
          for (let i = 0; i < relatedHits.length; i++) {
            if (relatedHits[i]._source.originalId === relatedRecord.parameter) {
              documentId = relatedHits[i]._id;
              documentTitle = relatedHits[i]._source.title
              break;
            }
          }
        }

        if (documentId && documentTitle) {
          return (
            <div className={'line'} key={relatedRecord.parameter}>
              <div className={'label linkLabel'}>{relatedRecord.name}:</div>
              <Link to={`/elements/${documentId}/${relatedRecord.parameter}`} className="value linkValue">{documentTitle}</Link>
            </div>
          )
        }

        return (
          <div className={'line'} key={relatedRecord.title}>
            <div className={'label linkLabel'}>{relatedRecord.name}:</div>
            <span className="value linkValue" onClick={() => setSnackbar({ open: true, message: 'A dokumentum csak külső adatbázisban érhető el.' })}>{relatedRecord.title}</span>
          </div>
        )
      })

    }

    return relatedRecordsDisplay;
  }




  return (
    <React.Fragment>
      {displayRelatedDocs()}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbarHandler}
      >
        <ErrorSnackbar
          onClose={closeSnackbarHandler}
          variant="error"
          message={snackbar.message}
        />
      </Snackbar>
    </React.Fragment>
  )
}

export default RelatedRecords;