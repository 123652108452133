import React, { useEffect, useState } from 'react';

//HOC
import SubPageLayout from '../layouts/subpage_layout';
import BreadcrumbsLayout from '../layouts/breadcrumbs_layout';

//CSS
import {
  Box,
  Grid,
  Button,
  Snackbar
} from '@material-ui/core';

import '../styles/basic_style.scss';
import styles from '../pages/index.module.scss';
import elementTemplateStyles from './element_template.module.scss';


//COMPONENTS
import Head from '../components/head';
import HoldingsTable from '../components/HoldingsTable/HoldingsTable';
import DocumentFuncButton from '../components/DocumentFuncButtons/DocumentFuncButtons';
import MoreLikeThis from '../components/MoreLikeThis/MoreLikeThis'
import ErrorSnackbar from '../components/ErrorSnackbar/ErrorSnackbar';
import RelatedRecords from '../components/RelatedRecords/RelatedRecords';

//GASTBY
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { navigate, graphql, useStaticQuery } from 'gatsby';

const APOLLO_QUERY = gql`
    query($id: String!){
    elasticDocument(where: {id: $id}){
      documentPayload
    }
  }
`


const GET_DOCTYPE_DATA_STATIC_QUERY = graphql`
        query getDocdataForElements {
          allDoctypeMappingCsv {
            nodes {
              hun
              key
              svg
              eng
            }
          }
      }
`

const ElementTemplate = props => {

  useEffect(() => {
    if (new URLSearchParams(window.location.search).get('toPrint') === 'true') {
      setTimeout(() => {
        window.print();
      }, 500)
    }
  }, [])



  const [errorModal, setErrorModal] = useState({
    open: false,
    errorMessage: null
  })

  const closeErrorModalHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorModal({ ...errorModal, open: false })
  }

  let fromElements = false;

  if (props.location.state)
    fromElements = true;

  let documentId = props.location.pathname[10];
  for (let i = 11; i < props.location.pathname.length; i++) {
    if (props.location.pathname[i] === '/')
      break;
    documentId += props.location.pathname[i]
  }

  const staticData = useStaticQuery(GET_DOCTYPE_DATA_STATIC_QUERY)
  const { allDoctypeMappingCsv } = staticData


  const { data, loading, error } = useQuery(APOLLO_QUERY, {
    variables: {
      id: documentId ? documentId : ''
    }
  });

  const typeIconDisplay = () => {
    const matchDoctypeData = allDoctypeMappingCsv.nodes.find(node => node.hun === data.elasticDocument.documentPayload._source.type)
    if (!matchDoctypeData) {
      const matchElseStaticData = allDoctypeMappingCsv.nodes.find(node => node.key === 'else')
      return matchElseStaticData.svg
    }
    return matchDoctypeData.svg
  }

  const parseArray = (value) => {
    if (Array.isArray(value)) {
      let output = '';
      if (value) {
        for (let i = 0; i < value.length; i++) {
          i === value.length - 1 ? output += value[i] : output += value[i] + ' '
        }
        return output;
      }
    }
    return value;
  }

  const parseMegjelenes = () => {
    const megjelenesArray = [];

    if (data.elasticDocument.documentPayload._source.placeOfAppearance) {
      if (data.elasticDocument.documentPayload._source.placeOfAppearance.length > 0) {
        megjelenesArray.push(data.elasticDocument.documentPayload._source.placeOfAppearance)
      }
    }

    if (data.elasticDocument.documentPayload._source.publisher) {
      if (data.elasticDocument.documentPayload._source.publisher.length > 0) {
        megjelenesArray.push(data.elasticDocument.documentPayload._source.publisher)
      }
    }

    if (data.elasticDocument.documentPayload._source.dateOfAppearance) {
      if (data.elasticDocument.documentPayload._source.dateOfAppearance.length > 0) {
        megjelenesArray.push(data.elasticDocument.documentPayload._source.dateOfAppearance)
      }
    }

    if (megjelenesArray.length === 0) return 'Nem ismert hely, kiadó, dátum'

    return parseArray(megjelenesArray);
  }


  let pageDisplay = null;
  let pageTitle = "Tartalom oldal";


  if (error) {
    pageTitle = "Hiba a lekérdezés közben"
    // pageDisplay = (
    //   <div className={elementTemplateStyles.errorContainer}>
    //     <div>Hiba merült fel lekérdezés közben.</div>
    //     <div>Bővebb információ: {error.message}</div>
    //   </div>
    // )
    

    //SNACKBAR
    if (errorModal.errorMessage === null) {
      if (error.graphQLErrors[0]) {
        if (error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR' && error.message.includes('internal server error')) {
          setErrorModal({ open: true, errorMessage: 'Az adatlap jelenleg nem elérhető vagy a dokumentum nem létezik az adatbázisban.' })
        }
        else if (error.graphQLErrors[0].extensions.code === 'INTERNAL_SERVER_ERROR' && error.message.includes('Too many requests')) {
          setErrorModal({ open: true, errorMessage: 'Ön elérte az egy percen belül indítható maximális bejelentkezési kísérlet számát, kérjük próbálkozzon újra egy perc múlva.' })
        }
      }
      else {
        setErrorModal({ open: true, errorMessage: 'Hálózati hiba' })
      }
    }
  }

  if (loading) {
    pageDisplay = <center><div className={elementTemplateStyles.ldsSpinner}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></center>
  }

  if (data) {

    // history.replaceState({}, "", data.elasticDocument.documentPayload._source.originalId);
    // history.replaceState({}, "", documentId + '/' + data.elasticDocument.documentPayload._source.originalId);
    // window.history.pushState( {} , 'bar', documentId + '/' + data.elasticDocument.documentPayload._source.originalId );
    // history.replaceState(null, null, "bar2");

    pageTitle = data.elasticDocument.documentPayload._source.title;
    console.log(data.elasticDocument.documentPayload._source)
    const documentSummarizedValues = [
      {
        name: 'Cím',
        value: data.elasticDocument.documentPayload._source.fullTitle
      },
      {
        name: 'Szerző',
        value: data.elasticDocument.documentPayload._source.author
      },
      {
        name: 'Megjelenés',
        value: parseMegjelenes()
      },
      {
        name: 'Forrás',
        value: data.elasticDocument.documentPayload._source.library
      }
    ]

    const documentDetailsValues = [
      {
        name: 'Dokumentum típus',
        value: data.elasticDocument.documentPayload._source.type
      },
      {
        name: 'Eredeti azonosító',
        value: data.elasticDocument.documentPayload._source.originalId
      },
      {
        name: 'ISBN',
        value: data.elasticDocument.documentPayload._source.isbn
      },
      {
        name: 'ISSN',
        value: data.elasticDocument.documentPayload._source.issn
      },
      {
        name: 'Cím',
        value: data.elasticDocument.documentPayload._source.fullTitle
      },
      {
        name: 'Szerző',
        value: data.elasticDocument.documentPayload._source.author
      },
      {
        name: 'Megjelenés helye',
        value: data.elasticDocument.documentPayload._source.placeOfAppearance
      },
      {
        name: 'Megjelenés éve',
        value: data.elasticDocument.documentPayload._source.dateOfAppearanceString
      },
      {
        name: 'Nyomdász / Kiadó',
        value: data.elasticDocument.documentPayload._source.publisher
      },
      {
        name: 'Possessor',
        value: data.elasticDocument.documentPayload._source.possessor
      },
      {
        name: 'Nyelv',
        value: data.elasticDocument.documentPayload._source.language
      },
      {
        name: 'Terjedelem',
        value: data.elasticDocument.documentPayload._source.format
      },
      {
        name: 'Hivatkozás',
        value: data.elasticDocument.documentPayload._source.reference
      },
      {
        name: 'Általános megjegyzés',
        value: data.elasticDocument.documentPayload._source.generalNote
      },
      {
        name: 'Kéziratos bejegyzés',
        value: data.elasticDocument.documentPayload._source.manuscript
      },
      {
        name: 'Megjegyzés a tartalomról',
        value: data.elasticDocument.documentPayload._source.contentNote
      },
      {
        name: 'Megjegyzés a mű bibliográfiai kapcsolatáról',
        value: data.elasticDocument.documentPayload._source.bibliographicConnectionNote
      },
      {
        name: 'Tárgyszavak',
        value: data.elasticDocument.documentPayload._source.subject
      },
      {
        name: 'Bejelentő könyvtár',
        value: data.elasticDocument.documentPayload._source.library
      }
    ]

    const relatedRecords = [
      {
        name: 'Kapcsolódó rekord (eredeti nyelvű információk)',
        value: data.elasticDocument.documentPayload._source.relatedRecord.linkingEntryOtherRelationship
      },
      {
        name: 'Kapcsolódó rekord (fordítás)',
        value: data.elasticDocument.documentPayload._source.relatedRecord.linkingEntryTranslation
      },
      {
        name: 'Kapcsolódó rekord (melléklet, különszám)',
        value: data.elasticDocument.documentPayload._source.relatedRecord.linkingEntrySupplementSpecial
      },
      {
        name: 'Kapcsolódó rekord (alapdokumentuma)',
        value: data.elasticDocument.documentPayload._source.relatedRecord.linkingEntrySupplementParent
      },
      {
        name: 'Kapcsolódó rekord (forrásdokumentuma)',
        value: data.elasticDocument.documentPayload._source.relatedRecord.linkingEntryHostItem
      },
      {
        name: 'Kapcsolódó rekord (részdokumentuma / részegysége)',
        value: data.elasticDocument.documentPayload._source.relatedRecord.linkingEntryConstituent
      },
      {
        name: 'Kapcsolódó rekord (további kiadások más hordozón)',
        value: data.elasticDocument.documentPayload._source.relatedRecord.linkingEntryAdditionalPhysicalForm
      },
      {
        name: 'Kapcsolódó rekord (előzménye)',
        value: data.elasticDocument.documentPayload._source.relatedRecord.linkingEntryPreceding
      },
      {
        name: 'Kapcsolódó rekord (folytatása)',
        value: data.elasticDocument.documentPayload._source.relatedRecord.linkingEntrySucceeding
      },
      {
        name: 'Kapcsolódó rekord (kötet, füzet adatok)',
        value: data.elasticDocument.documentPayload._source.relatedRecord.linkingEntryOriginalLanguage
      }
    ]

    const dataToHoldingsTable = [...data.elasticDocument.documentPayload._source.holding];
    for (let i = 0; i < data.elasticDocument.documentPayload._source.quarry.length; i++) {
      dataToHoldingsTable[i] = { ...dataToHoldingsTable[i], quarry: [data.elasticDocument.documentPayload._source.quarry[i]] }
    }
    if (data.elasticDocument.documentPayload._source.quarry.length === 0 && data.elasticDocument.documentPayload._source.holding.length !== 0) {
      dataToHoldingsTable[0] = { ...dataToHoldingsTable[0], quarry: [] }
    }

    pageDisplay = (
      <React.Fragment>
        <Box className={'docDataMoreHeadline'}>
          <span>Összefoglalás</span>
        </Box>
        <div className={elementTemplateStyles.osszefoglalasContainer}>
          <div className={elementTemplateStyles.typeImgContainer}>
            <Box className={elementTemplateStyles.typeImgContaincerBox}>
              <img src={typeIconDisplay()} alt='dokumentum típus' />
            </Box>
          </div>
          <div className={elementTemplateStyles.osszefoglalasTextContainer}>
            <Grid
              container
              className={'docDataImportant'}
              direction="column"
            >
              <h4 className={'value'}>
                {data.elasticDocument.documentPayload._source.type
                  ? data.elasticDocument.documentPayload._source.type
                  : "Nem ismert típus."
                }
              </h4>
              {documentSummarizedValues.map(documentValue => (
                <div className={'line'} key={documentValue.name}>
                  <div className={'label'}><b>{documentValue.name}:</b></div>
                  <div className={'value'}>
                    {!documentValue.value || documentValue.value.length === 0
                      ? "Nem ismert"
                      : parseArray(documentValue.value)
                    }
                  </div>
                </div>
              ))}
            </Grid>
          </div>
        </div>
        <Box className={elementTemplateStyles.boxDataContainer}>
          <DocumentFuncButton
            url={window.location.href}
            data1={data.elasticDocument.documentPayload._source.marc}
            data2={data.elasticDocument.documentPayload._source.xml}
          />
        </Box>
        <Box className={'docDataMoreHeadline'}>
          <span>Részletek</span>
        </Box>
        <Box className={"docDataFull"}>

          {documentDetailsValues.map(documentValue => {
            let documentValueDisplay = null;
            if (!documentValue.value || documentValue.value.length !== 0) {
              if (documentValue.value !== null) {
                documentValueDisplay = (
                  <div className={'line'} key={documentValue.name}>
                    <div className={'label'}>{documentValue.name}:</div>
                    <div className={'value'}>{parseArray(documentValue.value)}</div>
                  </div>
                )
              }
            }
            return documentValueDisplay
          })}

          <RelatedRecords relatedRecords={relatedRecords} />

        </Box>
        <HoldingsTable data={dataToHoldingsTable} />
        <MoreLikeThis documentId={documentId} />
      </React.Fragment >
    );
  }

  return (
    <React.Fragment>
      <SubPageLayout>
        <Head title={pageTitle} />
        <BreadcrumbsLayout
          menuItems={[
            { name: 'Főoldal', path: '/' },
            { name: 'Elements', path: '/element_list' },
            { name: pageTitle }
          ]}
        >
          <div className={styles.textContentContainer}>
            <h3>Adatlap</h3>
            <div className={'dataBox'}>
              {pageDisplay}
              <Box className={"docDataBackLink"}>
                {fromElements
                  ?
                  <Button
                    variant="contained"
                    className={'backButton'}
                    // onClick={() => window.history.back()}
                    onClick={() => navigate('/element_list?keyword=')}
                  >
                    Vissza a találati listára
                  </Button>
                  :
                  <Button
                    variant="contained"
                    className={'backButton'}
                    onClick={() => navigate('/')}
                  >
                    Vissza a főoldalra
                  </Button>
                }
              </Box>
            </div>
          </div>
        </BreadcrumbsLayout>
      </SubPageLayout>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={errorModal.open}
        autoHideDuration={6000}
        onClose={closeErrorModalHandler}
      >
        <ErrorSnackbar
          onClose={closeErrorModalHandler}
          variant="error"
          message={errorModal.errorMessage}
        />
      </Snackbar>
    </React.Fragment>
  )
}

export default ElementTemplate
