import React, { useState } from 'react';

//CSS
import {
  Box,
  Button,
  Grid,
  MobileStepper
} from '@material-ui/core';
import '../../styles/basic_style.scss';
import styles from './MoreLikeThis.module.scss';
import { useTheme } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

//COMPONENTS
import MoreLikeThisElement from './MoreLikeThisElement/MoreLikeThisElement';

//GRAPHQL
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Transition from 'react-transition-group/Transition';



const APOLLO_QUERY = gql`
query($id: String!){
  elasticMoreLikeThis(where: {id: $id}, from: 0, size:10){
    documentsPayload
  }
}
`

const MoreLikeThis = props => {
  const { data, loading, error } = useQuery(APOLLO_QUERY, {
    variables: {
      id: props.documentId
    }
  });

  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [maxSteps, setMaxSteps] = useState(null);
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  let elements = [];
  let elementsDisplay = [];

  if (loading) {
    elementsDisplay = [<center key='spinner'><div className={styles.ldsSpinner}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></center>];
  }


  if (error) {
    elementsDisplay = [
      <div key={'gaphqlError'}>
        <center>Hiba az adatok lekérdezése közben.</center>
        <div>Több információ: {error.message}</div>
      </div>];
  }

  if (data) {
    if (data.elasticMoreLikeThis.documentsPayload.hits.hits.length !== 0) {
      for (let i = 0; i < data.elasticMoreLikeThis.documentsPayload.hits.hits.length; i++) {
        elements.push({
          id: data.elasticMoreLikeThis.documentsPayload.hits.hits[i]._id,
          originalId: data.elasticMoreLikeThis.documentsPayload.hits.hits[i]._source.originalId,
          index: i,
          type: data.elasticMoreLikeThis.documentsPayload.hits.hits[i]._source.type,
          title: data.elasticMoreLikeThis.documentsPayload.hits.hits[i]._source.fullTitle,
          author: data.elasticMoreLikeThis.documentsPayload.hits.hits[i]._source.author,
          dateOfAppearance: data.elasticMoreLikeThis.documentsPayload.hits.hits[i]._source.dateOfAppearance,
          placeOfAppearance: data.elasticMoreLikeThis.documentsPayload.hits.hits[i]._source.placeOfAppearance,
          publisher: data.elasticMoreLikeThis.documentsPayload.hits.hits[i]._source.publisher,
          library: data.elasticMoreLikeThis.documentsPayload.hits.hits[i]._source.library,
        })
      }

      if (!maxSteps) {
        setMaxSteps(elements.length)
      }

      elementsDisplay = (
        <React.Fragment>


          {/* {[-1, 0, 1].map(value => (
            <React.Fragment key={value}>
              <Transition in={activeStep + value} timeout={1000}>
                {
                  state => {
                    // console.log(state)
                    return (
                      elements[activeStep + value] ? (
                        <MoreLikeThisElement
                          state={state}
                          title={elements[activeStep + value].title}
                          author={elements[activeStep + value].author}
                          type={elements[activeStep + value].type}
                          dateOfAppearance={elements[activeStep + value].dateOfAppearance}
                          placeOfAppearance={elements[activeStep + value].placeOfAppearance}
                          publisher={elements[activeStep + value].publisher}
                          library={elements[activeStep + value].library}
                          id={elements[activeStep + value].id}
                          originalId={elements[activeStep + value].originalId}
                        />
                      ) : <MoreLikeThisElement invisible />
                    )
                  }
                }
              </Transition>
            </React.Fragment>
          ))} */}

          {
            [-1, 0, 1].map(value => (
              <Transition in={elements[activeStep + value]} timeout={1000}>
                {
                  state => {
                    console.log(state)
                    return (
                      elements[activeStep + value] ?
                        <MoreLikeThisElement
                          selected={value===0}
                          title={elements[activeStep + value].title}
                          author={elements[activeStep + value].author}
                          type={elements[activeStep + value].type}
                          dateOfAppearance={elements[activeStep + value].dateOfAppearance}
                          placeOfAppearance={elements[activeStep + value].placeOfAppearance}
                          publisher={elements[activeStep + value].publisher}
                          library={elements[activeStep + value].library}
                          id={elements[activeStep + value].id}
                          originalId={elements[activeStep + value].originalId}
                        />
                        :
                        <MoreLikeThisElement invisible />
                    )
                  }
                }
              </Transition>
            ))
          }


        </React.Fragment>
      )
    }
  }


  return (
    data && data.elasticMoreLikeThis.documentsPayload.hits.hits.length !== 0 ?
      <React.Fragment>
        <Box className={"docDataMoreHeadline noBottomSpace"}>
          <span>Hasonló dokumentumok</span>
        </Box>
        <Box className={[styles.docDataMoreLikeThis, "docDataMoreLikeThis"].join(' ')}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            direction="row"
            wrap='nowrap'
          >
            {elementsDisplay}
          </Grid>

          <MobileStepper
            steps={maxSteps}
            position="static"
            variant="text"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Következő
              {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                    <KeyboardArrowRight />
                  )}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                    <KeyboardArrowLeft />
                  )}
                Előző
            </Button>
            }
          />


        </Box>

      </React.Fragment>
      :
      null
  )
}

export default MoreLikeThis;
