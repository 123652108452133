import React, { useState, useEffect } from 'react';
import orderBy from 'lodash/orderBy';

//CSS
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from '@material-ui/core';
import ArrowDropDownSharpIcon from '@material-ui/icons/ArrowDropDownSharp';
import ArrowDropUpSharpIcon from '@material-ui/icons/ArrowDropUpSharp';
import styles from './HoldingsTable.module.scss';

const HoldingsTable = (props) => {
    const [showTable, setShowTable] = useState(props.fromElementsList ? false : true)

    const [sort, setSort] = useState('');
    const [sortDirection, setSortDirection] = useState('desc');

    const [data, setData] = useState(null);


    useEffect(() => {
        if (props.data.length === 0) {
            setShowTable(false)
        }

        const tempData = [];
        for (let i = 0; i < tableData['quarryCode'].values.length; i++) {
            const tempObject = {};
            for (let key in tableData) {
                tempObject[tableData[key].name] = tableData[key].values[i]
            }
            tempData.push(tempObject);
        }
        setData(tempData);
    }, [props.data])

    const toggleTableHandler = () => {
        if (props.data.length !== 0)
            setShowTable(!showTable);
    }

    const tableData = {
        quarry: {
            name: 'Lelőhely',
            id: 'quarry',
            values: []
        },
        quarryCode: {
            name: 'Lelőhelykód',
            id: 'quarryCode',
            values: []
        },
        parcelNumber: {
            name: 'Helyrajzi szám',
            id: 'parcelNumber',
            values: []
        },
        instanceType: {
            name: 'Példánytípus',
            id: 'instanceType',
            values: []
        },
        quarryInstitution: {
            name: 'Egyéb',
            id: 'quarryInstitution',
            values: []
        }
    }

    Object.keys(tableData).forEach(field => {
        for (let i = 0; i < props.data.length; i++) {
            if (props.data[i][field]) {
                let fieldValues = '';
                if (props.data[i][field].length === 0) {
                    fieldValues = 'Nincs információ';
                }
                else {
                    for (let k = 0; k < props.data[i][field].length; k++) {
                        if (k === 0) {
                            fieldValues += props.data[i][field][k]
                        }
                        else {
                            fieldValues += ', ' + props.data[i][field][k]
                        }
                    }
                }
                tableData[field].values.push(fieldValues);
            }
            else{
                tableData[field].values.push('Nincs információ')
            }
        }
    })

    const upArrow = (<React.Fragment><ArrowDropUpSharpIcon /></React.Fragment>)
    const downArrow = (<React.Fragment><ArrowDropDownSharpIcon /></React.Fragment>)
    let header = (
        <div
            className={[styles.header, showTable ? styles.open : styles.close].join(' ')}
            onClick={toggleTableHandler}
        >
            <span className={styles.headerLabel}>{props.data.length >= 2 ? 'Példányok:' : 'Példány:'}</span>
            <span className={styles.headerValue}>( {props.data.length} )</span>
            <span className={styles.headerStatusIcon}>{showTable ? upArrow : downArrow}</span>
        </div>
    )

    if (props.data.length === 0) {
        header = (
            <div className={[styles.header, styles.headerZero, styles.tooltip].join(' ')}>
                <span className={styles.headerLabel}>Példány: </span>
                <span className={styles.headerValue}>( {props.data.length} )</span>
                <span className={styles.headerStatusIcon}>
                    <ArrowDropDownSharpIcon />
                </span>
                <div className={styles.tooltiptext}>Nincs megjelenítendő adat</div>
            </div>
        )
    }

    const invertDirection = {
        asc: 'desc',
        desc: 'asc'
    }

    const handleSort = field => {
        setSortDirection(sort === tableData[field].name ? invertDirection[sortDirection] : 'asc')
        setSort(tableData[field].name);
        setData(orderBy(data, tableData[field].name, sortDirection));
    }

    const setTableHeader = field => {
        let arrow = downArrow;
        if (sortDirection === 'desc')
            arrow = upArrow;

        const tableHead = (
            <TableCell align={field === 'quarry' ? 'inherit' : 'center'} key={tableData[field].name} onClick={() => handleSort(field)} className={styles.headerProperty}>{tableData[field].name}<span className={styles.sortArrow}>{sort === tableData[field].name ? arrow : null}</span></TableCell>
        )
        return tableHead;
    }



    let tableHeadDisplay = null;
    if (showTable) {
        tableHeadDisplay = Object.keys(tableData).map(field => setTableHeader(field))
    }


    return (
        <React.Fragment>
            <Box className={[styles.tableContainer, "tableContainer"].join(' ')} >
                <Box className={showTable ? styles.openTable : styles.closeTable}>
                    {header}
                </Box>
                <Box className={[styles.instanceListTableContainer, "instanceListTableContainer"].join(' ')}>
                    <Table
                        stickyHeader
                        className={[
                            styles.instanceListTable,
                            showTable ? styles.openTable : styles.closeTable
                        ].join(' ')}
                    >
                        <TableHead>
                            <TableRow>
                                {tableHeadDisplay}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showTable && data ?
                                data.map(row => {
                                    const tempBody = Object.keys(row).map(field => {
                                        if (field === 'Lelőhely') {
                                            return <TableCell component="td" scope="row" key={field}>
                                                {row[field]}
                                            </TableCell>
                                        }
                                        return (<TableCell align="right" key={field}>{row[field]}</TableCell>);
                                    })
                                    return [<TableRow key={row['Lelőhelykód']}>{tempBody}</TableRow>]
                                })
                                :
                                null
                            }
                        </TableBody>
                    </Table>
                </Box>
            </Box >
        </React.Fragment>
    );
}

export default HoldingsTable;
