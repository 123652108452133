import React from 'react';

//CSS
import {
  Box,
  Card,
  Grid,
  Tooltip,
  Zoom
} from '@material-ui/core';
import styles from './MoreLikeThisElement.module.scss';

//GRAPHQL
import { graphql, useStaticQuery, Link } from 'gatsby';

const GET_DOCTYPE_DATA_STATIC_QUERY = graphql`
        query {
          allDoctypeMappingCsv {
            nodes {
              hun
              key
              svg
              eng
            }
          }
      }
`

const MoreLikeThisElement = props => {

  const staticData = useStaticQuery(GET_DOCTYPE_DATA_STATIC_QUERY)
  const { allDoctypeMappingCsv } = staticData

  const typeIconDisplay = () => {
    const matchDoctypeData = allDoctypeMappingCsv.nodes.find(node => node.hun === props.type)
    if (!matchDoctypeData) {
      const matchElseStaticData = allDoctypeMappingCsv.nodes.find(node => node.key === 'else')
      return matchElseStaticData.svg
    }
    return matchDoctypeData.svg
  }

  const parseArray = (value) => {
    if (Array.isArray(value)) {
      let output = '';
      if (value) {
        for (let i = 0; i < value.length; i++) {
          i === value.length - 1 ? output += value[i] : output += value[i] + ' '
        }
        return output;
      }
    }
    return value;
  }

  let author = '';

  author = parseArray(props.author);

  const parseMegjelenes = () => {
    const megjelenesArray = [];

    if (props.placeOfAppearance) {
      if (props.placeOfAppearance.length > 0) {
        megjelenesArray.push(props.placeOfAppearance)
      }
    }

    if (props.publisher) {
      if (props.publisher.length > 0) {
        megjelenesArray.push(props.publisher)
      }
    }

    if (props.dateOfAppearance) {
      if (props.dateOfAppearance.length > 0) {
        megjelenesArray.push(props.dateOfAppearance)
      }
    }

    if (megjelenesArray.length === 0) return 'Nem ismert hely, kiadó, dátum'

    return parseArray(megjelenesArray);
  }

  return (
    !props.invisible ?
    <Grid item className={[styles.singleElement, props.selected ? styles.singleElementActive : null]}
    style={
      {
        transform: props.state === 'exiting' ? 'translateX(-200px)' : null
      }
    }
    >
      <Link to={'/elements/' + props.id + '/' + props.originalId} style={{ textDecoration: 'none' }}>
        <Card className={[styles.elementContainer, props.selected ? styles.elementContainerActive : null].join(' ')}>
          <Box className={styles.documentTypeImgContainer}>
            <div className={styles.imageFrame}>
              <img src={typeIconDisplay()} alt='dokumentum típus' />
            </div>
          </Box>
          <div className={styles.dataRow}>
            <Tooltip
              title={props.title}
              classes={{ tooltip: styles.tooltipStyle }}
              TransitionComponent={Zoom}
            >
              <div>
                <span className={styles.dataLabel}><b>Cím:</b> </span>
                <span className={styles.dataValue}>{props.title}</span>
              </div>
            </Tooltip>
          </div>
          <div className={styles.dataRow}>
            <Tooltip
              title={author ? author : 'Nem ismert'}
              classes={{ tooltip: styles.tooltipStyle }}
              TransitionComponent={Zoom}
            >
              <div>
                <span className={styles.dataLabel}><b>Szerző:</b> </span>
                <span className={styles.dataValue}>{author}</span>
              </div>
            </Tooltip>
          </div>
          <div className={styles.dataRow}>
            <Tooltip
              title={parseMegjelenes()}
              classes={{ tooltip: styles.tooltipStyle }}
              TransitionComponent={Zoom}
            >
              <div>
                <span className={styles.dataLabel}><b>Megjelenés:</b> </span>
                <span className={styles.dataValue}>{parseMegjelenes()}</span>
              </div>
            </Tooltip>
          </div>
          <div className={styles.dataRow}>
            <Tooltip
              title={props.library ? props.library : 'Nem ismert'}
              TransitionComponent={Zoom}
              classes={{ tooltip: styles.tooltipStyle }}
            >
              <div>
                <span className={styles.dataLabel}><b>Forrás:</b> </span>
                <span className={styles.dataValue}>{props.library}</span>
              </div>
            </Tooltip>
          </div>
        </Card>
      </Link>
    </Grid>
    :
    <Grid item className={[styles.singleElement].join(' ')} style={{visibility: 'hidden'}} />
  )
}

export default MoreLikeThisElement;
